// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { RootContainer, SEOContainer } from '.';
import { Article, Link, Main, H1, H2, P } from '~components';

// ─────────────────────────────────────────────────────────────────────────────
// query
// ─────────────────────────────────────────────────────────────────────────────

export const query = graphql`
  query($path: String!) {
    article: mdx(frontmatter: { meta: { permalink: { eq: $path } } }) {
      frontmatter {
        ...MetaFragment
        title
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
  }
`;

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function BlogPost({
  location,
  data: {
    article: {
      frontmatter: { title, date, meta },
      body,
    },
  },
}) {
  return (
    <RootContainer location={location}>
      <SEOContainer meta={meta} />
      <Main
        gridTemplateColumns="var(--width-outside) 1fr var(--width-outside)"
        padding={{
          xs: '20rem 0 10rem',
          lg: '20rem 20rem 10rem',
        }}
      >
        <Article itemScope itemType="http://schema.org/BlogPosting" gridColumn="2">
          <header style={{ margin: '0 0 4rem' }}>
            <H1 itemProp="name" margin="0 0 3rem 0">
              <Link to={meta.permalink} itemProp="url">
                {title}
              </Link>
            </H1>
            {/* {meta.description && (
              <P fontSize="3rem">{parseLinks(meta.description, { type: 'primary' })}</P>
            )} */}
            {/* {date && (
              <H2 as="time" dateTime={new Date(date).toISOString()} itemProp="datePublished">
                {date}
              </H2>
            )} */}
          </header>
          <MDXRenderer>{body}</MDXRenderer>
        </Article>
      </Main>
    </RootContainer>
  );
}
